import User from "../model/User"
import client from "../config/axios"
import Guild from "../model/Guild"

type WalluUser = {
  terms_and_privacy_accepted: boolean
}

export async function getProfile(): Promise<{ user: User, guilds: Guild[], wallu_user: WalluUser }> {
  const data = await client.get("/auth/me")
  return data.data
}

export async function acceptTermsAndPrivacy(): Promise<void> {
  await client.post("/accept-terms-and-privacy")
}

export async function logout({ redirect }: any) {
  await client.post("/auth/logout")
  window.location.href = redirect
}

export async function checkVotes(guildId: any, userId: any): Promise<VoteRewardResponse> {
  const data = await client.get(`/vote-rewards/${guildId}/${userId}`)
  return data.data
}

export type VoteRewardResponse = {
  streak_expires_at: string,
  credits_expires_at: string,
  total_credits_from_voting: number,
  new_votes: number
  new_credits: number
}
